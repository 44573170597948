.about-wrapper{
    .inner-hero{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 40px;

        .column_one{
            width: 21%;
            .tomchi-image{
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .tomchi-links{
                display: flex;
                align-items: center;
                justify-content: space-between;
                img{
                    width: 100px;
                    height: 100px;
                    object-fit: contain;
                }
            }

            .platform-apps{
                margin-top: 30px;
                justify-content: space-around;
                width: 100%;
            }
            .active-link{
                ul{
                    width: 100%;
                    background: #F6F6F7;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    .slider-navbar{
                        display: none;
                    }
                    li{
                        display: block;
                        button{
                            padding: 7px 15px;
                            text-align: left;
                            border: none;
                            background-color: transparent;
                            border-radius: 10px;
                            width: 100%;
                            display: block;
                            font-family: 'Montserrat',
                            sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: #282F38;
                            white-space: nowrap;
                            &.active{
                                background: #3987F3;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .column_two{
            width: 56%;
            .place-holder{
                border-bottom: 1px solid #ccc;
                margin-bottom: 20px;
                padding-bottom: 24px;
                .center-holder{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #2D2D2D;
                    position: relative;
                    padding-left: 41px;
                    &::before {
                        position: absolute;
                        content: '';
                        width: 25px;
                        height: 7px;
                        background: #3987F3;
                        border-radius: 10px;
                        left: 0;
                        bottom: 0;
                        top: 0px;
                        margin: auto;
                    }
                }

                .center-title{
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: lighter;
                    font-size: 16px;
                    line-height: 33px;
                    color: #2A2A2A;
                }

                .center-overview{
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 19px;
                    line-height: 23px;
                    color: #2A2A2A;
                    margin-bottom: 20px;
                }

                
            }
            .main-image {
                display: none;
            }

            .main-page_overview{
                .place-block{
                    position: relative;
                    margin-bottom: 30px;
                    &::before
                    {
                        position: absolute;
                        content: '';
                        bottom: -15px;
                        width: 70%;
                        left: 0;
                        right: 0;
                        margin: auto;
                        background-color: rgb(189, 189, 189);
                        height: 2px;
                    }
                    .place-name{
                        font-family: Nunito Sans;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 27px;
                        text-align: justify;
                        text-transform: capitalize;
                        color: #2A2A2A;
                        margin-bottom: 5px;
                    }
                    ul{
                        li{
                            list-style-type:  disc;
                            margin-left: 29px;
                            font-family: Nunito Sans;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 27px;
                            text-align: justify;
                            text-transform: capitalize;
                            color: #2A2A2A;
                        }
                    }
                }

                .end-title{
                    list-style-type: disc;
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 27px;
                    text-align: justify;
                    text-transform: capitalize;
                    color: #2A2A2A;
                    margin-bottom: 50px;
                }
            }

            .reja-maqsad {
                padding: 0 0 24px 40px;
                border-bottom: 1px solid #ccc;
                margin-bottom: 24px;
                .reja-title{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #2D2D2D;
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        left: -45px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        width: 25px;
                        background: #3987F3;
                        border-radius: 10px;
                        height: 7px;
                    }
                }
            }

            .maqsadlar{
                margin-bottom: 10px;
                h3{
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 33px;
                    text-align: justify;
                    color: #2A2A2A;
                    padding-left: 22px;
                    margin-bottom: 10px;
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #C4C4C4;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
                p{
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: justify;
                    color: #2A2A2A;
                    margin-bottom: 10px;
                }
            }

            .direc-card{
                background: #FFFFFF;
                border: 1px solid #CDD1DD;
                box-sizing: border-box;
                border-radius: 5px;
                width: 100%;
                margin-bottom: 72px;
                .card-top{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 30px 0 30px 30px;
                    .profile-photo{
                        border: 1px solid #CDD1DD;
                        border-radius: 5px;
                        overflow: hidden;
                        width: 30%;
                        height: 250px;
                        img{
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .card-info{
                        width: 55%;
                        p{
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 26px;
                            color: #282F38;
                        }
                        h2{
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 26px;
                            color: #282F38;
                            margin-bottom: 35px;
                        }

                        a{
                            padding: 10px 38px;
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 26px;
                            color: #FFFFFF;
                            background: #3987F3;
                            box-shadow: 0px 20px 20px rgba(57, 135, 243, 0.15);
                            border-radius: 60px;
                        }
                    }
                }
                .card-bottom{
                    padding: 30px;
                    background: #F6F6F7;
                    border-radius: 0px 0px 5px 5px;

                    button{
                        padding: 7px 15px;
                        width: 187px;
                        border: none;
                        background-color: transparent;
                        background: #FFFFFF;
                        border: 1px solid #B6BCCD;
                        box-sizing: border-box;
                        border-radius: 10px;
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 20px;
                        color: #3987F3;
                        cursor: pointer;
                        outline: none;

                        &:nth-child(1){
                            margin-right: 24px;
                        }

                        &.active{
                            color: #777B82;
                            background: #F6F6F7;
                            border: 1px solid #B6BCCD;
                        }
                        &.activeTwo{
                            color: #777B82;
                            background: #F6F6F7;
                            border: 1px solid #B6BCCD;
                        }
                    }

                    .open-info{
                        margin-top: 20px;
                        display: none;
                        &.active{
                            display: block;
                        }
                        .infos{
                            display: flex;
                            align-items: center;
                            padding: 20px 0;
                            &.border{
                                border-bottom: 1px dashed #B6BCCD;
                            }

                            span{
                                display: block;
                                width: 250px;
                                padding-left: 20px;
                                font-family: Poppins;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 17px;
                                line-height: 22px;
                                color: #282F38;
                            }
                            a, h4{
                                font-family: Poppins;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 17px;
                                line-height: 22px;
                                color: #282F38;
                            }
                        }
                    }

                    .another-info{
                        display: none;
                        &.active{
                            display: block;
                        }
                    }
                }
            }

            .nizom-title{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 24px;
                color: #2D2D2D;
                position: relative;
                border-bottom: 1px solid #ccc;
                padding: 0 0 24px 40px;
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 9px;
                    width: 25px;
                    background: #3987F3;
                    border-radius: 10px;
                    height: 7px;
                }
        }
        }

        .column_thr{
            width: 20%;
            .block-holder{
                h2{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 22px;
                    color: #2D2D2D;
                    margin-bottom: 24px;
                    position: relative;
                    padding-left: 45px;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        left: 0;
                        background: #3987F3;
                        border-radius: 10px;
                        width: 25px;
                        height: 7px;
                        margin: auto;
                    }
                }
            }
        }
    }
}

.page-loading{
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    bottom: 0;
    right: 0;
    background-color: #000;
    width: 100%;
    min-height: 100vh;
    z-index: 22;
}