.menu-wrapper{
    padding: 62px 50px 100px 50px;
    box-sizing: border-box;
    background: #FFFFFF;
    width: 100%;
    overflow: hidden;
    .inner-menu{
        .menu-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            label{
                position: relative;
                width: 80%;
                display: block;
                input {
                    background: #F1F4F7;
                    border: 1px solid #F1F4F7;
                    box-sizing: border-box;
                    border-radius: 13px;
                    padding: 19px 10px 17px 72px;
                    width: 100%;
                    margin-right: 33px;
                }

                img {
                    position: absolute;
                    z-index: 1;
                    top: 15px;
                    width: 24px;
                    height: 24px;
                    left: 22px;
                    object-fit: contain;
                }
            }

            button{
                border: none;
                background-color: transparent;
            }
        }
        .header-bottom{
            border-bottom: 1px dashed #d8d8d8;
            padding: 0 30px 0 20px;


            .dropdown {
                margin: 0;
                padding: 0;
                list-style: none;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                text-align: left;

                .drop-link_name{
                    color: #000;
                    font-size: 18px;
                    &::before{
                        position: absolute;
                        content: '';
                        width: 14px;
                        height: 11px;
                        background-color: #3987F3;
                        clip-path: polygon(48% 40%, 0% 100%, 100% 100%);
                        top: 5px;
                        bottom: 0;
                        margin: auto;
                        right: -10px;
                        transform: rotate(180deg);
                    }

                    &.another{
                        &::before{
                            display: none;
                        }
                    }

                    &.activeTwo{
                        &::before{
                            display: none;
                        }

                        & +  .open-links{
                            display: none;
                        }
                    }
                }
            }
    
            .dropdown li {
                position: relative;
            }
    
            .dropdown li a {
                color: #ffffff;
                text-align: center;
                text-decoration: none;
                display: block;
                padding: 10px;
                text-align: left;
            }
    
            .dropdown li ul {
                position: relative;
                top: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                display: none;
                line-height: normal;
                background: #E8F0FD;
                text-align: left;
            }
    
            .dropdown li ul li a {
                text-align: left;
                color: #000;
                font-size: 16px;
                padding: 10px;
                display: block;
                white-space: nowrap;
                text-align: left;
            }
    
            .dropdown li ul li a:hover {
                    color: #fff;
                    background: #40a9ff;
            }
    
            .dropdown li ul li ul {
                left: 100%;
                top: 0;
            }

            ul li:hover>ul {
                display: block;
            }
        }
    }
}

.languages{
    margin-top: 30px;
    ul{
        display: flex;
        align-items: center;
        li{
            display: block;
            margin: 0 30px 0 0;
            button{
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #696868;
                opacity: 0.7;
                transition: 0.2s;
                border: none;
                background-color: transparent;
                &:hover{
                    color: #000;
                }
                &.active{
                    color: #000;
                    font-weight: bolder;
                }
            }
        }
    }
}