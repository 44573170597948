@media screen and (max-width: 1000px) {
    .newspage{
        .inner-page{
            .column-thre{
                display: none;
            }

            .column-two{
                width: 100%;
            }

            .column-one{
                width: 100%;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                overflow: hidden;
                flex-direction: column;
                .ul-wrapper{
                    display: flex;
                    align-items: center;
                    overflow: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                    width: 100%;
                    &.two{
                        margin-top: 10px;
                    }
                    ul{
                        margin-bottom: 0;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        button{
                            border-bottom: none;
                            padding: 5px 20px;
                            white-space: nowrap;
                            width: 100%;
                            &.active{
                                background-color:  #3987F3;
                                a{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

                .tomchi{
                    display: none;
                }
                span{
                    display: none;
                }
                .tomchi-links{
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .newspage{
        .inner-page{
            .column-two{
                .section{
                    .section-card{
                        margin: 20px 0;
                        padding: 15px;
                        .right-side{
                            h2{
                                font-size: 16px;
                                margin-bottom: 10px;
                            }
                            p{
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .newspage{
        .inner-page{
            .column-two{
                .section{
                    .section-card{
                        margin: 20px 0;
                        flex-direction: column !important;
                        display: flex;
                        height: 100%;
                        .left-side{
                            margin-bottom: 20px;
                            width: 100%;
                            height: 196px;
                        }

                        .right-side{
                            width: 100%;
                        }
                    }
                }

                .announcement{
                    .ad-card{
                        padding: 15px;
                        .right{
                            h2{
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                    .section-card{
                        margin: 20px 0;
                        flex-direction: column !important;
                        display: flex;
                        padding: 15px;
                        height: 100%;
                        .left-side{
                            margin-bottom: 10px;
                            width: 100%;
                            height: 196px;
                        }

                        .right-side{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .newspage{
        .inner-page{
            .column-one{
                .ul-wrapper{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    ul{
                        overflow: scroll;
                        button{
                            padding: 5px;
                            &.active{
                                background-color: #3987F3;
                                border-radius: 10px;
                            }
                            a{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}