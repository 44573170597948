@media screen and (max-width: 1000px) {
    .seminar-wrapper{
        .main-page{
            .col-one{
                display: none;
            }
            .col-thre{
                display: none;
            }

            .col-two{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:540px) {
    .seminar-wrapper{
        .main-page{
            .col-two{
                .exper-card{
                    display: flex;
                    flex-direction: column;
                    .files{
                        display: flex;
                        margin-top: 20px;
                        width: 100%;
                        justify-content: space-around;
                        flex-direction: row;
                        align-items: center;
                        .file-size{
                            margin-bottom: 0;
                        }
                    }
                    .download-text{
                        .card-image{
                            width: 150px;
                            height: 150px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:440px) {
    .seminar-wrapper{
        .main-page{
            .col-two{
                .exper-card{
                    .download-text{
                        .card-image{
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                        }
                        .card-title{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}