@media screen and (max-width: 1000px) {
    .project-page{
        .inner-project{
            flex-direction: column;

            .column-thr{
                display: none;
            }

            .column-one{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                background: #F6F6F7;
                flex-direction: column;

                .open-block {
                    border: none;
                    width: 100%;
                }

                .open-blocks {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                }
                .tomchi{
                    display: none;
                }

                .categories{
                    .cate-ul{
                        display: flex;
                        overflow-x: scroll;
                        justify-content: space-between;
                        &::-webkit-scrollbar{
                            display: none;
                        }
                        .cate-btn{
                            min-width: 150px;
                            margin: 0 5px;
                        }
                    }
                }

                #cate-name{
                    margin: 10px 0;
                }

                .tomchi-links{
                    display: none;
                }
            }

            .column-two {
                width: 100%;
                margin-top: 40px;
            }
        }
    }   
}


@media screen and (max-width: 500px) {

    .project-page{
        .inner-project {
            .column-one {
                .open-block {
                    .opener {
                        .button {
                            padding: 7px 4px;
                            font-size: 11px;
                            line-height: 15px;
                        }
                    }
                }
                .categories{
                    .cate-ul{
                        .cate-btn{
                            padding: 4px 10px;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }

            .column-two {
                    .donr-cards{
                        .exper-card {
                            margin-bottom: 20px;
                            padding: 20px 13px;
                            flex-direction: column;

                            .download-text {
                                .card-title {
                                    font-size: 16px;
                                }
                            }

                            .files {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                flex-direction: row;
                                margin-top: 20px;

                                span {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    .project-card{
                        .section-card{
                            padding: 17px;
                            flex-direction: column;
                            .left-side{
                                width: 100%;
                            }

                            .right-side{
                                width: 100%;
                                margin-top: 20px;
                            }
                        }
                    }
            
                }
        }
    }
}