.footer-wrapper{
        .footer-top{
            padding: 38px 0 52px 0;
            background-color: #262A2E;
            position: relative;
            .top-inner{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }

            .footer-block_one{
                width: 27%;
                &:nth-child(2){
                    width: 20%;
                }
                &:nth-child(3) {
                    width: 20%;
                }
                &:nth-child(4) {
                    width: 20%;
                }
                .block-holder{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: 0.2px;
                    color: #FFFFFF;
                    margin-bottom: 30px;
                }

                .block-text{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.1px;
                    color: #F6F8FB;
                    margin-bottom: 20px;
                }

                .social-ul {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    li{
                        display: block;
                        margin-right: 12px;
                    }
                }

                .link-ul{
                    li{
                        margin-bottom: 20px;
                        display: block;
                        .page-link{
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 24px;
                            letter-spacing: 0.1px;
                            color: #FFFFFF;
                        }
                    }
                }

                .adress-name{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1px;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }

                .our-info{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.1px;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                    display: block;
                    position: relative;
                    padding-left: 30px;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 3px;
                        left: 0;
                        width: 17px;
                        height: 17px;
                        background-size: contain;
                        
                        background-repeat: no-repeat;
                    }
                    &.one{
                        &::before{
                            background-image: url('../../assets/icons/locat.png');
                        }
                    }
                    
                    &.two{
                        &::before{
                            background-image: url('../../assets/icons/phones.png');
                        }
                    }
                    &.thr {
                        &::before {
                            background-image: url('../../assets/icons/mail.png');
                        }
                    }
                }

                .maps{
                    width: 100%;
                    height: 277px;
                    border-radius: 10px;
                    overflow: hidden;
                    iframe{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }


        .footer-bottom{
            background: #121212;
            padding: 12px 0;
            .inner-bottom{
                p{
                    text-align: center;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.1px;
                    color: #F6F8FB;
                }
            }
        }
}

.fixed-link{
    .sms-btn{
        background-color: transparent;
        border: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #3987F3;
        cursor: pointer;
        margin-top: 10px;
    }

    form{
        padding: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        box-shadow: rgba(255, 255, 255, 0.16) 0px 10px 36px 0px, rgba(255, 255, 255, 0.06) 0px 0px 0px 1px;
        position: absolute;
        background-color: #000;
        z-index: 2;
        top: 0;
        width: 250px;
        height: 100%;
        width: 0;
        overflow: hidden;
        transition: 0.3s all ease;
        left: 0;
        &.active{
            width: 250px;
            padding: 10px 20px;
            overflow: visible;
        }
        img{
            width: 150px;
            margin: 0 auto 20px auto;
            display: flex;
            justify-content: center;
            text-align: center;
        }
        input, textarea{
            display: block;
            padding: 5px 10px;
            outline: none;
            border: none;
            margin-bottom: 10px;
            border-radius: 7px;
            background-color: rgb(104, 103, 103);
            color: #fff;
            width: 100%;
            &::placeholder{
                color: #fff;
            }
        }
        textarea{
            height: 100px;
            resize: none;
            margin-bottom: 20px;
        }
        button{
            width: 100%;
            border: none;
            border: 1px solid #3987f3;
            background-color: transparent;
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            transition: 0.2s;
            padding: 6px 20px;
            margin: 0 5px 0 0;
            &:nth-child(2){
                border: 1px solid #f00;
            }
        }
        .btns{
            display: flex;
            align-items: center;
        }
    }
}

.platform-apps{
    display: flex;
    justify-content: space-between;
    img{
        width: 120px;
    }
}