.knowledge-package{
    .hero-page{
        margin-bottom: 40px;
    }
    .inner-page{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .column-one{
            width: 21%;
            .tomchi{
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .next-open_holder{
                padding: 30px 0;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
            }
            .open-blocks_on{
                background: #F6F6F7;
                border-radius: 10px;
                width: 100%;
                overflow: hidden;
                .open-block{
                    border-bottom: 1px solid rgb(224, 224, 224);
                    &:last-child{
                        border-bottom: none;
                    }
                    .opener{
                        width: 100%;
                        button{
                            text-align: left;
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            width: 100%;
                            color: #282F38;
                            display: block;
                            padding: 13px 10px 13px 30px;
                            position: relative;
                            border: none;
                            &.active{
                                color: #fff;
                                background: #3987F3;

                                &::before{
                                    transform: rotate(0);
                                    background-color: #fff;
                                }
                            }

                            &::before{
                                position: absolute;
                                clip-path: polygon(48% 27%, 0% 100%, 100% 100%);
                                content: '';
                                width: 11px;
                                height: 7px;
                                background: #3987F3;
                                left: 10px;
                                transform: rotate(180deg);
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                    }

                    .opened{
                        display: none;
                        &.active{
                            display: block;
                        }
                        .blocks-ul{
                            margin: 0;
                                .blocks-link{
                                    font-family: Poppins;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #282F38;
                                    width: 100%;
                                    display: block;
                                    padding: 7px 10px 7px 40px;
                                    word-wrap: break-word;
                                    border: none;
                                    background-color: #dadada;
                                    text-align: left;

                                    &.active{
                                        background-color: #9dc1f3;
                                        color: #fff;
                                    }

                                    &.active + .last-openers{
                                        display: block;
                                    }
                                }

                                .last-openers{
                                    display: none;
                                    .blocks-link-two{
                                        font-family: Poppins;
                                            font-style: normal;
                                            font-weight: normal;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: #282F38;
                                            width: 100%;
                                            display: block;
                                            padding: 7px 10px 7px 50px;
                                            word-wrap: break-word;
                                            border: none;
                                            background-color: #c3c3c3;
                                            text-align: left;
                                            display: block;
                                            &.active{
                                                background-color: #7eadf0;
                                                color: #fff;
                                            }
                                    }
                                }
                        }
                    }
                }
            }
        }

        .column-two{
            width: 57%;
            .news-holder {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                padding-bottom: 24px;
                position: relative;
                padding-left: 41px;
                border-bottom: 1px solid #d9e0e9;

                &::before {
                    position: absolute;
                    content: '';
                    width: 25px;
                    height: 7px;
                    background: #3987F3;
                    border-radius: 10px;
                    left: 0;
                    bottom: 0;
                    top: -24px;
                    margin: auto;
                }
            }
        }

        .column-thr{
            width: 18%;
            .block-holder{
                h2{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 22px;
                    color: #2D2D2D;
                    margin-bottom: 24px;
                    position: relative;
                    padding-left: 45px;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        left: 0;
                        background: #3987F3;
                        border-radius: 10px;
                        width: 25px;
                        height: 7px;
                        margin: auto;
                    }
                }
            }
        }
    }

}

.tomchi{
    margin-top: 40px;
    width: 100%;
    a{
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}