.para{
    color: yellow;
}
.page-wrapper{
    font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif;
    .inner{
        display: flex;
        margin: 40px 0;
align-items: flex-start;
justify-content: space-between;
    }

.col-one{
    width: 18%;
    .link-tomchi{
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.col-two{
    width: 58%;
    .main-image{
        width: 100%;
        object-fit: cover;
        height: 300px;
    }
    .page-title{
        margin-bottom: 30px;
    }
}

.col-thr{
    width: 20%;
    .block-one{
        h1{
            font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;


color: #2D2D2D;
position: relative;
margin-bottom: 20px;
padding-left: 40px;
&::before{
    position: absolute;
    width: 25px;
    height: 7px;
    background: #3987F3;
border-radius: 10px;
content: '';
top: 0;
bottom: 0;
margin: auto;
left: 0;

}
        }
    }
}
}



.below-icons {
    display: grid;
    grid-column-gap: 10px;
    align-items: left;
    display: flex;
    position: relative;
}
.inner-div img{
    height: 15px;
    width: 15px;
    margin-right: 6px;
    object-fit: contain;
}

.inner-div{
    &:first-child{
        margin-right: 15px;
    }
}

.inner-div{
    display: flex;
    align-items: center;
}
.share-btn{
    a{
        img{
            width: 37px;
            height: 37px;
            object-fit: contain;
        }
    }
}

.sheyr-btns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 65%;
}

.central-img {
    margin: 30px 0;
}

.second-para{
    margin-top: 30px;
    margin-bottom: 60px;
}

      .ul-wrapper{
            span{
                display: block;
                margin-bottom: 20px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                padding-left: 13px;
            }
                ul{
                    background: #F6F6F7;
                    border-radius: 10px;
                    overflow: hidden;
                    li{
                        display: block;
                        padding: 7px 0 ;
                        background: #F6F6F7;
                        border-bottom: 1px solid rgba(216, 216, 216, 0.6);
                        &:last-child{
                            border-bottom: none;
                        }
                            &.active{
                                background-color:  #3987F3;
                                button{
                                    color: #fff;
                                }
                            }
                        button{
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            color: #282F38;
                        }
                    }

                    button{
                        border: none;
                        background-color: transparent;
                        display: block;
                        width: 100%;
                        padding: 10px 0;
                        cursor: pointer;
                        font-weight: bolder;
                        border-bottom: 1px solid rgba(216, 216, 216, 0.6);
                        &:last-child{
                            border-bottom: none;
                        }
                        &.active{
                            background-color: #3987F3;
                            color: #fff;
                            font-weight: bolder;
                        }
                    }
                }
            }
            .events-date{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                margin-top: 20px;
                .dates{
                    width: 48%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 11px 20px;
                    box-sizing: border-box;
                    background: #F4F4F4;
                    border-radius: 6px;
                    h4{
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: justify;
                        color: #000000;
                    }

                    span{
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: justify;
                        position: relative;
                        &::before{
                            position: absolute;
                            content: '';
                            top: 0;
                            bottom: 0;
                            left: -20px;
                            margin: auto;
                            background-image: url('../../assets/icons/date.png');
                            width: 14px;
                            height: 14px;
                            background-size: contain;
                        }
                    }
                }
            }


            .share-btn-two{
                min-width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 50%;
                cursor: pointer;
                img{
                    width: 15px;
                    height: 15px;
                    margin: 0 !important;
                }
            }

            .share-icons-two{
                background-color: #fff;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                display: flex;
                align-items: center;
                border-radius: 30px;
                padding: 5px;
                overflow: hidden;
                position: absolute;
                right: 0;
                

                .social-icons{
                    position: relative;
                    width: 0;
                    overflow: hidden;
                    transition: 0.5s all ease;
                    &.active{
                        overflow: visible;
                        width: 112px;
                    transition: 0.5s all ease;

                    }
                    ul{
                        display: flex;
                        margin: 0;
                        padding: 0;
                        li{
                            display: block;
                            margin-right: 10px;
                            &:nth-child(1){
                                margin-left: 10px;
                            }

                            a{
                                position: relative;
                                top: 50px;
                                transition: 0.3s;
                                &.active{
                                    top: 0;
                                    &:nth-child(1){
                                        transition-delay: 0.3s;
                                    }
                                    &:nth-child(2){
                                        transition-delay: 0.5s;
                                    }
                                    &:nth-child(3){
                                        transition-delay: 0.7s;
                                    }
                                }
                            }
                        }
                    }
                }
            }