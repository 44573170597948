.page-wrapper{
    .hero-place{
        margin-bottom: 70px;
    }
    .main-place{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 110px;

        .col-one{
            width: 18%;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }



            .section-ul{
                width: 100%;
                margin-bottom: 30px;
                border-radius: 15px;
                overflow: hidden;
                background-color: #f4f4f4;
                .section-btn{
                    width: 100%;
                    display: block;
                    padding: 10px;
                    box-sizing: border-box;
                    border: none;
                    background-color: transparent;
                    font-size: 17px;
                    &.active{
                        background-color: #3987F3;
                        color: #fff;
                    }
                }
            }
            .tomchi-links{
                display: flex;
                align-items: center;
                justify-content: space-between;
                img{
                    width: 100px;
                    height: 100px;
                    object-fit: contain;
                }
            }
        }

        .col-thre{
            width: 20%;
            h1{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                padding-left: 40px;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    width: 25px;
                    height: 7px;
                    border-radius: 10px;
                    background-color: #3987F3;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }

        .col-two{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 58%;
            h1{
                margin-bottom: 30px;
                position: relative;
                padding-left: 40px;
                &::before{
                    position: absolute;
                    content: '';
                    width: 25px;
                    height: 7px;
                    border-radius: 20px;
                    background-color: #3987F3;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .div{
                display: flex;
                width: 100%;
                margin-bottom: 70px;
                justify-content: space-between;
            }
            .water-card{
                padding: 42px 0;
                width: 48%;
                border-bottom: 1px solid rgba(51, 51, 51, 0.308);
                &:nth-child(1){
                    padding-top: 0;
                }
                &:nth-child(2){
                    padding-top: 0;
                }
                .top-image{
                    width: 100%;
                    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.16));
                    border-radius: 10px;
                    object-fit: contain;
                }
                .extra-text{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    width: 100%;
                    font-size: 16px;
                    line-height: 20px;
                    color: #2A2A2A;
                    height: 60px;
                    overflow: hidden;
                    margin: 23px 0 17px 0;
                }

                .description{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: justify;
                    color: #2A2A2A;
                    opacity: 0.6;
                    height: 100px;
                    overflow: hidden;
                }
            }
        }
    }
}