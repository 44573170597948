@media screen and (max-width: 650px) {
    .menu-wrapper{
        padding: 16px 33px 100px 20px;
        margin-right: 0;
        .inner-menu{
            .menu-top{
                label{
                    width: 100%;
                    input{
                        padding: 10px 10px 10px 50px;
                    }

                    img{
                        top: 10px;
                        width: 20px;
                        height:20px;
                        left: 10px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 450px) {
    .menu-wrapper {
        .inner-menu {
            .menu-links{
                .link-wrapper{
                    margin-bottom: 20px;
                    .link-name{
                        font-size: 16px;
                    }

                    .li-wrapper{
                        .link-li{
                            padding: 15px 0;
                            margin-left: 20px;
                            a{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}