@media screen and (max-width:1000px) {
    .knowledge-package {
        .hero-page {
            margin-bottom: 0;
        }

        .inner-page {
            flex-direction: column;
            .column-one {
                background: #F6F6F7;
                padding: 10px 0;
                width: 100%;
                display: flex;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                .next-open_holder {
                    display: none;
                }
                .tomchi{
                    display: none;
                }

                .open-blocks_on {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    overflow-x: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                    &.child-two{
                        display: none;
                    }
                    .open-block {
                        height: 100%;
                        width: 250px;
                        padding: 5px 10px;
                        box-sizing: border-box;
                        border-bottom: 1px solid transparent;

                        &:last-child {
                            border-bottom: none;
                        }
                        .opener{
                            position: relative;
                            button{
                                white-space: nowrap;
                                padding: 5px 5px 5px 34px;
                                background-color: transparent;
                                &::before{
                                    left: 13px;
                                }
                                &.active{
                                    border-radius: 5px;
                                }
                            }
                        }
                        .opened {
                            display: none;
                            position: absolute;
                            background: #FFFFFF;
                            box-shadow: 0px 10px 30px rgba(113, 124, 140, 0.3);
                            border-radius: 8px;
                            left: 20px;
                            padding: 10px;
                            z-index: 999;

                            &.active {
                                display: block;
                            }

                            .blocks-ul {
                                    .blocks-link {
                                        padding: 5px 10px;
                                        border-radius: 5px;
                                        &:hover{
                                            background-color: #3987F3;
                                            color: #fff;
                                        }
                                    }
                            }
                        }
                    }
                }

                .tomchi-links{
                    display: none;
                }
            }

            .column-two {
                width: 100%;

                .news-holder {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #2D2D2D;
                    padding-bottom: 24px;
                    position: relative;
                    padding-left: 41px;
                    border-bottom: 1px solid #d9e0e9;

                    &::before {
                        position: absolute;
                        content: '';
                        width: 25px;
                        height: 7px;
                        background: #3987F3;
                        border-radius: 10px;
                        left: 0;
                        bottom: 0;
                        top: -24px;
                        margin: auto;
                    }
                }
            }

            .column-thr {
                display: none;
            }
        }

    }
}





@media screen and (max-width:600px) {
    .knowledge-package {

        .inner-page {
            flex-direction: column;

            .column-one {
                .open-blocks_on {
                    overflow-y: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                    .open-block {
                        min-width: 220px;
                        padding: 0;
                        margin-right: 10px;
                    }
                }
            }

            .column-two {
                margin-top: 24px;
                .section-card{
                    height: 100%;
                    flex-direction: column;
                    padding: 20px;
                    .left-side{
                        width: 100%;
                        margin-bottom: 22px;
                        height: 196px;
                    }

                    .right-side{
                        width: 100%;
                    }
                }
            }

            .column-thr {
                display: none;
            }
        }

    }
}