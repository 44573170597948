@media screen and (max-width: 1000px) {
    .hero-page{
        margin-bottom: 0;
    }

    .inner-page{
        flex-direction: column;
        .column-one{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: #F6F6F7;
            .open-block{
                border: none;
            }

            .open-blocks {
                display: flex;
            }
            .tomchi-links, .tomchi-link{
                display: none;
            }
        }
        .column-two{
            width: 100%;
            margin-top: 40px;
        }
        .column-thr{
            display: none;
        }
    }
}

@media screen and (max-width: 500px) {
    
    .inner-page{
        .column-one {
            .open-block {
                .opener {
                    a {
                        padding: 10px;
                        font-size: 12px;
                    }
                }
            }
        }
        .column-two{
            .exper-card{
                margin-bottom: 20px;
                padding: 20px 13px;
                flex-direction: column;
                .download-text{
                    .card-title{
                        font-size: 16px;
                    }
                }
                .files{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: row;
                    margin-top: 20px;
                    span{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}