.crumb{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 12;
    ul{
        display: flex;
        align-items: center;
        position: relative;
        z-index: 12;
        li{
            position: relative;
            z-index: 12;
            .link{
                position: relative;
                z-index: 12;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: center;
                text-decoration-line: underline;
                color: #282F38;
                &.actibe{
                    text-decoration-line: none;
                    opacity: 0.8;
                    z-index: 12;
                    position: relative
                }
            }
        }

        .line{
            position: relative;
            display: block;
            margin: 0 10px;
            width: 20px;
            height: 2px;
            background-color: #333;
            opacity: 0.6;
            z-index: 12;
        }
    }
}