.project-page{
    .inner-project{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .column-one{
            width: 21%;
            .open-blocks {
                background: #F6F6F7;
                border-radius: 10px;
                overflow: hidden;
            }

            .open-block {
                border-bottom: 1px solid rgb(224, 224, 224);

                &:last-child {
                    border-bottom: none;
                }

                .opener {
                    width: 100%;

                    .button {
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                        text-align: center;
                        color: #282F38;
                        display: block;
                        padding: 13px 0;
                        border: none;
                        cursor: pointer;
                        &.active {
                            color: #fff;
                            background: #3987F3;
                            border-radius: 10px;
                        }
                    }
                    .multimenu{
                        li{
                            a{
                                font-family: Poppins;
                                font-style: normal;
                                font-weight: normal;
                                line-height: 24px;
                                width: 100%;
                                text-align: center;
                                color: #282F38;
                                display: block;
                                padding: 13px 0;
                                border: none;
                                cursor: pointer;
                                border-bottom: 1px solid #e0e0e0;
                            }
                            .first-step{
                                font-size: 15px;
                                &.active{
                                    background-color: rgb(127, 174, 240);
                                    color: #fff;
                                }
                            }
                            .second-step{
                                font-size: 14px;
                                &.active{
                                    background-color: rgb(158, 193, 243);
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .tomchi{
                width: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
            }

            .categories{
                width: 100%;
                border-radius: 10px;
                overflow: hidden;
                background: #F6F6F7;
                .cate-ul{
                    width: 100%;
                    margin: 0;
                    .cate-btn{
                        border: none;
                            border-bottom: 1px solid rgb(224, 224, 224);
                            width: 100%;
                            background-color: transparent;
                            padding: 10px 0;
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            width: 100%;
                            color: #282F38;
                            display: block;
                            cursor: pointer;
                            &.active {
                                color: #fff;
                                background: #3987F3;
                                border-radius: 10px;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                }
            }
        }


        .column-two{
            .section-holder {
                border-bottom: 1px solid #ccc;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                margin-bottom: 24px;
                position: relative;
                padding-left: 41px;
                padding-bottom: 24px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 25px;
                    height: 7px;
                    background: #3987F3;
                    border-radius: 10px;
                    left: 0;
                    top: 10px;
                }
            }
            width: 56%;
            .project-card{
                .section-card{
                    margin-bottom: 24px;
                }
            }
        }

        .column-thr {
            width: 18%;
        }
    }
}

#cate-name{
    color: #000;
    margin: 30px 0 10px 0;
    font-size: 20px;
}