.chart-wrapper{
    display: flex;
}
.save-btn{
    border: none;
}

.chart-description{
    width: 100%;
    color: #000;
    height: 100px;
    overflow: scroll;
    &::-webkit-scrollbar{
        display: none;
    }
}

.wr-chr{
    width: 100%;
    height: 100%;
}