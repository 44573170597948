* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

body {
 
    background-color: #fff;
    &.dark{
        .logo{
            color: white;
        }
    }
    &.hidden{
        overflow: hidden;
    }
        &::-webkit-scrollbar {
          width: 7px;
        }
        
        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }
         
        &::-webkit-scrollbar-thumb {
          background: #3987F3; 
          border-radius: 10px;
        }
}

h1,h2,h3,h4,h5,h6,p,a,li,ul{
    margin: 0;
    padding: 0;
}

.container{
    width: 100%;
    max-width: 1366px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

button{
    cursor: pointer;
}


.pre-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #0e1120;
    width: 100%;
    position: fixed;
    z-index: 999;
    img{
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: contain;
        top: 0;
    }
}

.loader{
     font-size: 13em; 
     position: fixed;
     transform: translate(-5%, -5%);

     &:nth-child(1){
         color: transparent;
         -webkit-text-stroke:2px #03a9f4
     }

     &:nth-child(2){
        color: #03a9f4;
        animation: animate 2s ease-in-out infinite;
    }
}

.scrollTop {
    position: fixed; 
    width: 40px;
    bottom: 40px;
    align-items: center;
    height: 30px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    right: 30px;
    bottom: 30px;
    border: none;
    background-color: transparent;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
  }
  
  .scrollTop{
      &:hover{
          opacity: 1;
      }
  }

@keyframes animate {
    0%,100%{
        clip-path: polygon(0% 45%, 15% 44%, 32% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
    }

    50%{
        clip-path: polygon(0 60%, 16% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }



.tomchi-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
}

.container-fluid{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
@media (min-width: 576px){
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 768px){
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width: 992px)
{
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}
.col{
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}
.partners-logo {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
}
.partners-logo-inner {
    border: 1px solid #cdd1dd;
    padding: 10px;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.partners-logo-inner:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.partners-logo-inner img {
    width: 100%;
}
.partners-link {
    display: block;
    flex-basis: calc(24% + 7px);
}