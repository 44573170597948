@media screen and (max-width: 1000px) {
    .about-wrapper {
        .inner-hero {
            flex-direction: column;
            margin-top: 0;
            .column_one {
                width: 100%;
                margin-bottom: 40px;
                background-color: #F6F6F7;

                .tomchi-image{
                    display: none;
                }

                .tomchi-links{
                    display: none;
                }

                .active-link {
                    ul {
                        border-radius: 0;
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                        margin-bottom: 20px;
                        background-color: transparent;
                        margin-bottom: 0;
                        .simple-navbar{
                            justify-content: space-around;
                            display: flex;
                        }

                        li {
                            button {
                                    padding: 7px 23px;
                                    white-space: nowrap;
                                    text-align: center;
                            }
                        }
                    }
                }
            }

            .column_two {
                width: 100%;

                .direc-card{
                    .card-top{
                        .card-info{
                            p{
                                font-size: 18px;
                                line-height: 40px;
                            }
                            h2{
                                font-size: 30px;
                                line-height: 40px;
                            }
                        }
                    }
                }
            }

            .column_thr {
                display: none;
            }
        }
    }
}



@media screen and (max-width: 790px) {
    .about-wrapper{
        .inner-hero{
            .column_one{
                .active-link{
                    ul{
                        li{
                            a{
                                padding: 5px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 650px) {
    .about-wrapper {
        .inner-hero {
            .column_one {
                .active-link {
                    ul{
                        li{
                            width: auto;
                            text-align: center;
                            a{
                                font-size: 12px;
                                padding: 3px 5px;
                            }
                        }
                        .simple-navbar{
                            display: none;
                        }

                        .slider-navbar{
                            display: block;
                        }

                        .slick-dots{
                            display: none !important;
                        }

                        .slick-arrow{
                            display: none !important;
                        }
                    }
                }
            }

            .column_two {
                width: 100%;

                .direc-card {
                    .card-top {
                        padding: 20px 0 20px 10px;
                        .profile-photo{
                            height: 200px;
                        }
                        .card-info {
                            p {
                                font-size: 18px;
                                line-height: 40px;
                            }

                            h2 {
                                font-size: 30px;
                                line-height: 40px;
                            }
                        }
                    }

                    .card-bottom{
                        .open-info {
                            .infos {
                                padding: 15px 0;
                                span {
                                    width: 50%;
                                }

                                a,
                                h4 {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .about-wrapper{
        .inner-hero{
            .column_two
            .direc-card {
                .card-top {
                    .card-info {
                        p {
                            font-size: 12px;
                            line-height: 18px;
                            margin-bottom: 7px;
                        }

                        h2{
                            font-size: 14px;
                            line-height: 24px;
                            margin-bottom: 10px;
                        }
                        a{
                            padding: 5px 15px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 500px) {
    .about-wrapper {
        .inner-hero {
            .column_two .direc-card {
                .card-top {
                    display: flex;
                    justify-content: space-around;
                    .profile-photo{
                        width: 40%;
                        margin-right: 0;
                    }
                    .card-info{
                        width: 55%;
                    }
                }

                .card-bottom{
                    button{
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 450px) {
    .about-wrapper{
        .inner-hero{
            .column_one{
                .active-link{
                    ul{
                        li{
                            button{
                                font-size: 11px;
                                text-align: center;
                                padding: 3px 5px;
                            }
                        }
                    }
                }
            }
            .column_two{
                 .direc-card {
                     .card-top {
                         padding: 20px 0 20px 10px;

                         .profile-photo {
                             height: 200px;
                         }

                         .card-info {
                             p {
                                 font-size: 12px;
                                 line-height: 20px;
                             }

                             h2 {
                                 font-size: 20px;
                                 line-height: 30px;
                             }
                         }
                     }

                     .card-bottom {
                         padding: 10px;
                         .open-info {
                             margin-top: 20px;
                             display: none;

                             &.active {
                                 display: block;
                             }

                             .infos {
                                 display: flex;
                                 align-items: center;
                                 padding: 15px 0;

                                 &.border {
                                     border-bottom: 1px dashed #B6BCCD;
                                 }

                                 span {
                                     font-size: 13px;
                                 }

                                 a,
                                 h4 {
                                     font-size: 14px;
                                 }
                             }
                         }
                     }
                 }
            }
        }
    }
}