@media screen and (max-width: 1000px) {
    .footer-wrapper{
        .footer-top{
            .top-inner{
                flex-wrap: wrap;
            }

            .footer-block_one {
                width: 47%;
                margin-bottom: 40px;

                &:nth-child(2) {
                    width: 47%;
                }

                &:nth-child(3) {
                    width: 47%;
                }

                &:nth-child(4) {
                    width: 47%;
                }
            }
        }
    }   
}


@media screen and (max-width: 500px) {
    .footer-wrapper {
        .footer-top {
            padding: 30px 0;
            .top-inner {
                flex-wrap: wrap;
            }

            .footer-block_one {
                width: 100%;
                margin-bottom: 40px;

                &:nth-child(2) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                }

                &:nth-child(3) {
                    width: 100%;
                }

                &:nth-child(4) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}