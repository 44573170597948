.map-content{
    max-width: 1140px;
    width: 100%;
    padding: 30px 36px;
    margin: 0 auto;
    box-sizing: border-box;
}
.leaflet-container{
    height: 500px;
    width: 100%;
}
.custom-popup{
    bottom: 28px !important;
}