@media screen and (max-width:1000px) {
    .page-wrapper{
        .col-thr{
            display: none;
        }
        .inner{

        }
        .col-one{
            display: none;
        }

        .col-two{
            width: 100%;
        }
    }
}

@media screen and (max-width:700px) {
    .page-wrapper{
        .inner{
            flex-direction: column;
        }

        .col-two{
            width: 100%;
        }
    }
}

@media screen and (max-width:600px) {
    .page-wrapper{

        .col-two{
            width: 100%;
        }
        .col-two{
            .events-date{
                flex-direction: column;
                .dates{
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
    }
}