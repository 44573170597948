@media screen and (max-width: 1200px) {
    .events-page {
        .inner-pages {
            .col-one {
                width: 20%;

                .tomchi {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .col-thr {
                width: 17%;
            }

            .col-two {
                width: 60%;
            }
        }
    }
}



@media screen and (max-width: 1000px) {
    .events-page{
        .inner-pages{
            .col-one{
                display: none;
            }
            .col-thr{
                display: none;
            }

            .col-two{
                width: 100%;
                margin-top: 40px;
            }
        }
    }
}




@media screen and (max-width: 680px) {
    .events-page{
        .inner-pages{
            .col-two {
                .events-card {
                    .events-date {
                        margin-bottom: 0px;
                        flex-direction: column;

                        .dates {
                            margin-bottom: 20px;
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 630px) {
    .events-page {
        .inner-pages {
            .col-two {
                .events-card {
                    .events-date {
                        .dates {
                            margin-bottom: 20px;
                            width: 70%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .events-page {
        .inner-pages {
            .col-two {
                .events-card {
                    .events-date {
                        .dates {
                            margin-bottom: 20px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}