.section-card{
    display: flex;
    align-items: center;
    margin: 15px 0 15px 0;
    justify-content: space-between;
    padding: 10px;
    background: #F6F6F7;
    border: 1px solid #CDD1DD;
    box-sizing: border-box;
    height: 160px;
    border-radius: 5px;
    .left-side{
        width: 30%;
        position: relative;
        border-radius: 13px;
        height: 130px;
        overflow: hidden;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(
360deg, #2A2A2A 0%, rgba(97, 97, 97, 0) 54.17%, rgba(255, 255, 255, 0) 100%);
            height: 100%;
        }
        .bg-image {
            margin: auto;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .date {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 10px;
            left: 10px;

            span {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                text-align: justify;
                color: #FFFFFF;
                display: block;
                padding-left: 5px;
            }
        }

        .share-btn{
            bottom: 10px;
            right: 10px;
            border: none;
            background-color: transparent;
            position: absolute;
        }
    }

    .right-side{
        width: 65%;
        h2{
            font-family: IBM Plex Serif;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            color: #2A2A2A;
            margin-bottom: 14px;
        }

        .desc{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #2A2A2A;
            overflow: hidden;
            height: 35px;
        }
    }
}

.region-title
{
    display: flex;
    justify-content: space-between;
    p{
        height: auto;
        opacity: 0.8;
        font-size: 14px;
    }
}