.carousel_one{
    display: flex;
    align-items: center;
    width: 100%;
    &:hover{
        .right{
            .carousel-name, .carousel-title{
                color: #3987F3;
            }
        }
    }
    .left{
        margin-right: 15px;
        position: relative;
        overflow: hidden;
        border-radius: 13px;
        height: 200px;
        min-width: 200px;
        &::before {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background: linear-gradient(360deg, #2A2A2A 0%, rgba(97, 97, 97, -0.26) 54.17%, rgba(255, 255, 255, 0) 100%);
            width: 100%;
            height: 100%;
            border-radius: 3px;
        }

        .block-image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .pin-block{
            position: absolute;
            right: 10px;
            top: 10px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 165%;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            display: block;
            padding: 2px 6px 1px 17px;
            background: #3987F3;
            border-radius: 2px;
            clip-path: polygon(10% 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
        }

        .date-place{
            position: absolute;
            bottom: 6px;
            left: 16px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 165%;
            text-transform: lowercase;
            color: #FFFFFF;
        }

        .share-btn{
            position: absolute;
            bottom: 6px;
            right: 12px;
            background-color: transparent;
            border: none;
        }
    }


    .right{
        .carousel-name{
            font-family: IBM Plex Serif;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            color: #2A2A2A;
            width: 90%;
            margin-bottom: 10px;
            
        }


        .carousel-title{
            font-family: Nunito Sans;
            font-style: normal;
            overflow: hidden;
            font-weight: normal;
            font-size: 16px;
            line-height: 133.41%;
            color: #2A2A2A;
            white-space: no-wrap;
            text-overflow: ellipsis;
            max-width: 370px;
            
            
        }
    }
}