.seminar-wrapper{
    .main-page{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .col-one{
            width: 20%;
            a{
                img{
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .col-thre{
            width: 18%;
            h1{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                margin-bottom: 24px;
                position: relative;
                padding-left: 41px;

                &::before {
                    position: absolute;
                    content: '';
                    width: 25px;
                    height: 7px;
                    background: #3987F3;
                    border-radius: 10px;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    top: 0;
                }
            }
        }

        .col-two{
            width: 60%;
            .image-holder{
                img{
                    width: 100%;
                height: 300px;
                object-fit: cover;
                }
            }
            .app{
                margin-bottom: 40px;
                margin-top: 30px;
            }
            .materials{
                margin-bottom: 40px;
            }
            .sceldules{
                margin-bottom: 40px;
            }
            
        }
    }
}

.margin{
    width: 100%;
    height: 40px;
}