.card-wrapper{
    margin-bottom: 60px;
    .card-top{
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 380px;

        &::before{
            position: absolute;
            content: '';
            bottom: 0;
            width: 100%;
            height: 115px;
            background: linear-gradient(360deg, rgba(42, 42, 42, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            &::-webkit-scrollbar{
                display: none;
            }
        }
    }

    .card-bottom{
        .card-info{
            display: flex;
            align-items: flex-start;
            margin-top: 40px;
            margin-bottom: 20px;
            .card-theme{
                max-width: 469px;
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                display: flex;
                align-items: center;
                color: #263238;
                &:hover{
                    color: #3987F3;
                }
            }

            .info-icon{
                width: 21px;
                height: 21px;
                object-fit: contain;
            }

            .icon-wrap{
                padding: 10px 12px;
                background: #3987F3;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                margin-right: 10px;
            }
        }

        .card-title{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 165%;
            color: #2A2A2A;
            opacity: 0.6;
            max-width: 641px;
        }
    }
}