.selectBox {
    display: flex;
    // align-items: center;
    justify-content: center;
    background: #fff;
    -webkit-box-shadow: 0 4px 55px rgb(0 0 0 / 10%);
    box-shadow: 0 4px 55px rgb(0 0 0 / 10%);
    border-radius: 15px;
    width: 80%;
    padding: 50px;
    margin: 30px auto;
}
.select {
    font-weight: 500;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    &:focus {
        border-color: #fff;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgb(37 181 189 / 25%);
        box-shadow: 0 0 0 0.2rem rgb(37 181 189 / 25%);
    }
}
.projectWrapper {
    max-width: 1140px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
}
.title {
    font-weight: 600;
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 12px;
    color: #14c0ca;
    font-size: 28px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        width: 50%;
        height: 3px;
        background-color: #14c0ca;
        bottom: 0;
        left: 0;
    }
}
.tabChanger {
    margin-bottom: 22px;
}
.tabChangerButton {
    color: #bbb;
    padding: 10px 0;
    margin: 0 30px 0 0;
    font-weight: 600;
    background: none;
    border: none;
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 1.5;
}
.activeButton{
    color: #14c0ca;
}
.card {
    width: 100%;
    background-color: #fff;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-shadow: 0 4px 55px rgb(0 0 0 / 10%);
    box-shadow: 0 4px 55px rgb(0 0 0 / 10%);
    position: relative;
    background: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    height: 200px;
    -o-border-radius: 8px;
    margin-bottom: 30px;
    display: flex;
}
.option {
    padding: 20px;
}
.cardWrapper {
    // display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.cardImage {
    max-width: 330px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.dFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.readMore{
    margin-top: 12px;
    float: right;
    font-size: 1rem;
    display: inline-block
}
.description{
    color: #747474;
    font-size: 15px;
    font-weight: 400;
}