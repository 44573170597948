@media screen and (max-width: 1200px) {
    .home-wrapper{
        .hero-page{
            margin-top: 50px;

            .inner-hero{
                .right{
                    .holder {
                        flex-direction: column;
                        .holder-name {
                            font-size: 30px;
                            line-height: 38px;
                        }

                        .holder-title {
                            font-size: 14px;
                            line-height: 150%;
                        }
                    }
                }

                .left{
                    height: 500px;
                }
            }
        }

        .analys-information {
            .inner {

                .informations {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .info-card {
                        width: 240px;
                        padding: 18px 0 18px 20px;
                        .info-name {
                            h3 {
                                font-size: 16px;
                            }

                            .numbers {
                                display: flex;
                                align-items: center;

                                .image-icon {
                                    width: 50px;
                                    height: 50px;
                                }

                                span {
                                    font-size: 35px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .technologies{
            .block-infos {
                .cards {
                    width: 48%;
                    .card-wrapper{
                        width: 100%;
                        .card-top{
                            width: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    .home-wrapper {
        .hero-page {
            margin-top: 50px;

            .inner-hero {
                .right {
                    .holder {
                        .holder-name {
                            font-size: 27px;
                            line-height: 38px;
                            margin-bottom: 15px;
                        }

                        .holder-title {
                            font-size: 13px;
                            line-height: 150%;
                            margin-bottom: 15px;
                        }
                        .date{
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }

        .analys-information {
            .inner {

                .informations {
                    .info-card {
                        width: 230px;
                    }
                }
            }
            
        }
        .carousel-section_one {
            margin-top: 70px;
            width: 100%;
            margin: 70px 0 130px 0;

            .carousel-items {
                width: 100%;
                padding: 0 10px;
                .carousel_one{
                    .right{
                        .carousel-name{
                            font-size: 17px;
                            line-height: 20px;
                        }
                        .carousel-title {
                            font-size: 15px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        .charts-informations{
            .slick-arrow{
                &.slick-prev{
                    top: 108% !important;
                    left: 93%;
                }

                &.slick-next {
                    right: 0 !important;
                    top: 108% !important;
                }
            }
        }
    }
}


@media screen and (max-width: 900px) {
    .home-wrapper {
        .hero-page {
            margin-top: 50px;

            .inner-hero {
                .right {
                    .holder {
                        .holder-name {
                            font-size: 27px;
                            line-height: 38px;
                            margin-bottom: 15px;
                        }

                        .holder-title {
                            display: block;
                        }

                        .date {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }

        .analys-information {
            .inner {

                .informations {
                    justify-content: space-around;
                    .info-card {
                        width: 300px;
                        .info-name{
                            h3{
                                font-size: 18px;
                            }

                            .numbers{
                                .image-icon{
                                    width: 55px;
                                    height: 55px;
                                }
                                span {
                                    font-size: 44px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .carousel-section_one {
            margin-top: 70px;
            width: 100%;
            margin: 70px 0 130px 0;

            .carousel-items {
                width: 100%;
                padding: 0 10px;

                .carousel_one {
                    .right {
                        .carousel-name {
                            font-size: 17px;
                            line-height: 20px;
                        }

                        .carousel-title {
                            font-size: 15px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        .technologies {
            .inner{
                .block-heading{
                    .right-side{
                        .download-pdf{
                            display: none;
                        }
                    }
                }
            }
            .block-infos {
                .cards {
                    width: 48%;

                    .card-wrapper {
                        width: 100%;

                        .card-top {
                            width: 100%;
                            height: 240px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 849px) {
    .home-wrapper{
        .carousel-section_one {
            .carousel-items {
                .carousel_one {
                    .right {
                        .carousel-name {
                            font-size: 24px;
                            line-height: 35px;
                        }

                        .carousel-title {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }

        .technologies {
            .inner {
                .block-infos {
                    .cards {
                        width: 100%;

                        .card-wrapper {
                            width: 100%;

                            .card-top {
                                width: 100%;
                                height: 300px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .card-bottom{
                                .card-info{
                                    .card-theme{
                                        max-width: 100%;
                                    }
                                }

                                .card-title{
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .charts-informations{
            .slick-arrow{
                &.slick-prev{
                    top: 108% !important;
                    left: 80% !important;
                }

                &.slick-next {
                    right: 0 !important;
                    top: 108% !important;
                }
            }
        }
    }
}

@media screen and (max-width:750px) {
    .home-wrapper{
        .hero-page{
            .inner-hero{
                flex-direction: column;
                .left{
                    width: 100%;
                    height: 300px;

                }
                .right{
                    width: 100%;
                }
            }
        }

        .carousel-section_one {
            .carousel-items {
                .carousel_one {
                    .right {
                        .carousel-name {
                            font-size: 20px;
                            line-height: 25px;
                        }

                        .carousel-title {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 660px) {
    .home-wrapper {
        .hero-page{
            margin-top: 40px;
            .inner-hero{
                flex-direction: column;
                .left{
                    width: 100%;
                    height: 300px;
                }
                .right{
                    width: 100%;
                    margin-top: 20px;
                    .holder{
                        .holder-name{
                            font-size: 30px;
                            line-height: 43px;
                            margin-bottom: 20px;
                        }
                        .date{
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .carousel-section_one {
            .carousel-items {
                .carousel_one {
                    .right {
                        .carousel-name {
                            font-size: 20px;
                            line-height: 25px;
                        }

                        .carousel-title {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }

        .technologies {
            .inner {
                .block-infos {
                    .cards {
                        width: 100%;

                        .card-wrapper {
                            width: 100%;

                            .card-top {
                                width: 100%;
                                height: 300px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .card-bottom {
                                .card-info {
                                    .card-theme {
                                        max-width: 100%;
                                    }
                                }

                                .card-title {
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .analys-information{
            .inner{
                .informations{
                    flex-direction: column;
                    .info-card{
                        width: 90%;
                        padding: 28px 0 28px 60px;
                        .info-name{
                            h3{
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 530px) {
    .home-wrapper {
        .hero-page {
            margin-top: 20px;
            margin-bottom: 30px;

            .inner-hero {
                flex-direction: column;

                .left {
                    width: 100%;
                    height: 220px;
                }

                .right{
                    font-size: 24px;
                }
            }
        }

        .carousel-section_one {
            display: none;
        }

        .technologies {
            .inner {
                .block-heading{
                    flex-direction: column;
                    padding-bottom: 50px;
                    border-bottom: 1px dashed rgb(189, 188, 188);
                    .left-side{
                        h2{
                            font-size: 18px;
                            margin-bottom: 20px;
                        }
                    }

                    .right-side{
                        .primary_btn{
                            margin-left: 0;
                        }
                    }
                }
                .block-infos {
                    margin-bottom: 20px;
                    .cards {
                        width: 100%;

                        .card-wrapper {
                            width: 100%;
                            flex-direction: row;

                            .card-top {
                                width: 100%;
                                height: 146px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .card-bottom {
                                .card-info {
                                    .card-theme {
                                        max-width: 100%;
                                        font-size: 16px;
                                        line-height: 20px;
                                    }
                                }

                                .card-title {
                                    max-width: 100%;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }

                .charts-informations{
                    .chart-holder{
                        flex-direction: column;
                        h1{
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }

        .analys-information {
            .inner {
                .informations {
                    flex-direction: column;

                    .info-card {
                        width: 100%;
                        padding: 30px 0 30px 30px;

                        .info-name {
                            h3 {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        .usefull-links{
            padding: 40px 0 110px 0;

            .inner{
                .slick-arrow{
                    &.slick-prev{
                        left: 80%;
                    }
                }
            }
        }
    }
}