.hero-page{
    margin-bottom: 40px;
}

.inner-page{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .column-one{
        width: 21%;
        .tomchi-link{
            display: block;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .column-two{
        width: 56%;
        .holder-title{
            margin-bottom: 30px;
            border-bottom: 1px solid #ccc;
            h1{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                margin-bottom: 24px;
                position: relative;
                padding-left: 41px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 25px;
                    height: 7px;
                    background: #3987F3;
                    border-radius: 10px;
                    left: 0;
                    bottom: 0;
                    top: 0px;
                    margin: auto;
                }
            }
        }
        .exper-blocks{
            .heading{
                &.another{
                    margin-top: 37px;
                }
            }

            &.alone{
                margin-bottom: 100px;
            }
        }

        .main-link{
            font-family: IBM Plex Serif;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            text-decoration-line: underline;
            color: #3987F3;
        }


    }

    .column-thr{
        width: 18%;
        
    }
}
.news-holder {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #2D2D2D;
    margin-bottom: 24px;
    position: relative;
    padding-left: 41px;

    &::before {
        position: absolute;
        content: '';
        width: 25px;
        height: 7px;
        background: #3987F3;
        border-radius: 10px;
        left: 0;
        bottom: 0;
        top: 0px;
        margin: auto;
    }

    &.has-border{
        border-bottom: 1px solid #d9e0e9;
        padding-bottom: 24px;
        margin-bottom: 30px;
    }
}

.tomchi{
    width: 100%;
    a{
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}