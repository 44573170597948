.exper-card{
    padding: 17px;
    background: #F6F6F7;
    border: 1px solid #CDD1DD;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    .download-text{
        display: flex;
        align-items: center;
    }

    .card-image{
        width: 123px;
        height: 69px;
        object-fit: contain;
    }

    .card-title{
        font-family: IBM Plex Serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        color: #2A2A2A;
        padding-left: 16px;

    }

    .file-size{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #757B87;
        display: block;
        margin-bottom: 18px;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            width: 11.67px;
            height: 15.83px;
            left: -22px;
            background-size: contain;
            top: 0;
            bottom: 0;
            margin: auto;
            background-image: url('../../assets/icons/zip.png');
        }
    }

    .download-file{
        border: none;
        padding: 5px 22px 5px 46px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        color: #3987F3;
        background: rgba(57, 135, 243, 0.2);
        border-radius: 10px;
        width: 148px;
        cursor: pointer;
        position: relative;
        &.active{
            
        }
        &::before{
            content: '';
            position: absolute;
            width: 11.67px;
            height: 10.5px;
            left: 23px;
            background-size: contain;
            top: 0;
            bottom: 0;
            margin: auto;
            background-image: url('../../assets/icons/down.png');
        }
    }

    .files{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &.active{
            .download-file{
                background-color: transparent;
                color: #fff;
                cursor: disabled;
                pointer-events: none;
                content: 'none';
                padding: 0;
                display: flex;
                justify-content: flex-end;
                &::before{
                    display: none;
                }
            }
            .file-size{
                display: none;
            }
        }
        &.activeTwo{
            .download-file{
                background-color: rgb(192, 192, 192);
                color: #fff;
                cursor: disabled;
                pointer-events: none;
                &::before{
                    display: none;
                }
            }
            .file-size{
                display: none;
            }
            
        }
    }
}