.events-page{
    .inner-pages{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .col-one{
            width: 21%;
            .events-category{
                width: 100%;
                margin-bottom: 30px;
                border-radius: 10px;
                overflow: hidden;
                button{
                    display: block;
                    width: 100%;
                    text-align: center;
                    padding: 13px 0;
                    border: none;
                    background-color: #F6F6F7;
                    border-bottom: 1px solid #bbbbbb;
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            width: 100%;
                            color: #282F38;
                            &.active{
                                background-color: #3987F3;
                                color: #fff;
                            }
                    &:last-child{
                        border-bottom: 1px solid transparent;
                    }
                }
            }
            .tomchi{
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .event-link{
                display: block;
                width: 100%;
                padding: 15px 0 13px 34px;
                background: #F6F6F7;
                border-radius: 10px;
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #2A2A2A;

                &.active{
                    background-color: #3987F3;
                    color: #fff;
                }
            }
        }

        .col-two {
            width: 56%;
            .heading{
                border-bottom: 1px solid #ccc;
                margin-bottom: 24px;
            }
           .events-card{
               padding: 24px;
               background: #F6F6F7;
               border-radius: 10px;
               margin-bottom: 24px;

               .events-card_heading{
                   margin-bottom: 20px;
                   .events-theme{
                       font-family: Poppins;
                       font-style: normal;
                       font-weight: 600;
                       font-size: 14px;
                       line-height: 18px;
                       text-align: justify;
                       color: #000000;
                   }
               }

               .events-date{
                   display: flex;
                   align-items: center;
                   justify-content: space-between;
                   margin-bottom: 20px;
                   .dates{
                       width: 45%;
                       display: flex;
                       align-items: center;
                       justify-content: space-between;
                       padding: 11px 20px;
                       box-sizing: border-box;
                       background: #FFFFFF;
                       border-radius: 6px;
                       h4{
                           font-family: Poppins;
                           font-style: normal;
                           font-weight: normal;
                           font-size: 12px;
                           line-height: 18px;
                           text-align: justify;
                           color: #000000;
                       }

                       span{
                           font-family: Poppins;
                           font-style: normal;
                           font-weight: 600;
                           font-size: 12px;
                           line-height: 18px;
                           text-align: justify;
                           position: relative;
                           &::before{
                               position: absolute;
                               content: '';
                               top: 0;
                               bottom: 0;
                               left: -20px;
                               margin: auto;
                               background-image: url('../../assets/icons/date.png');
                               width: 14px;
                               height: 14px;
                               background-size: contain;
                           }
                       }
                   }
               }

               .events-title{
                   .card-title{
                       font-family: Poppins;
                       font-style: normal;
                       font-weight: normal;
                       font-size: 14px;
                       line-height: 18px;
                       text-align: justify;
                       color: #000000;
                   }
               }
           }
        }

        .col-thr {
            width: 18%;
        }
    }

    .margin{
        margin-bottom: 156px;
    }
}


.tomchi{
    margin-top: 0;
}