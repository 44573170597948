@media screen and (max-width: 1000px) {
  .container {
    .tomchi {
      flex-direction: column;
      .column_one {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #f6f6f7;
        flex-direction: column;
        ul {
          display: flex;
          overflow-x: scroll;
          justify-content: space-between;
          &::-webkit-scrollbar {
            display: none;
          }
          li {
            min-width: 150px;
            margin: 0 5px;
          }
        }
        img {
          display: none;
        }
        .tomchi-links {
          display: none;
        }
      }
      .column_two {
        width: 100%;
        margin-top: 40px;
        padding: 0 10px;
      }
      .column_three {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .tomchi {
      .column_one {
        ul {
          flex-wrap: wrap;
          li {
            padding: 7px 4px;
            font-size: 11px;
            line-height: 15px;
          }
        }
      }
      .column_two {
        width: 100%;
        margin-top: 40px;
      }
      .column_three {
        display: none;
      }
    }
  }
}
