   
.newspage{
    .inner-page{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .column-one{
            width: 20%;
            .ul-wrapper{
                ul{
                    background: #F6F6F7;
                    border-radius: 10px;
                    overflow: hidden;
                    li{
                        display: block;
                        padding: 7px 0 ;
                        background: #F6F6F7;
                        border-bottom: 1px solid rgba(216, 216, 216, 0.6);
                        &:last-child{
                            border-bottom: none;
                        }
                            &.active{
                                background-color:  #3987F3;
                                button{
                                    color: #fff;
                                }
                            }
                        button{
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            color: #282F38;
                        }
                    }
                    margin-bottom: 30px;

                    button{
                        border: none;
                        background-color: transparent;
                        display: block;
                        width: 100%;
                        padding: 10px 0;
                        cursor: pointer;
                        font-weight: bolder;
                        border-bottom: 1px solid rgba(216, 216, 216, 0.6);
                        &:last-child{
                            border-bottom: none;
                        }
                        &.active{
                            background-color: #3987F3;
                            color: #fff;
                            font-weight: bolder;
                        }
                    }
                }
            }

            span{
                display: block;
                margin-bottom: 10px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                padding-left: 13px;
            }

            .tomchi{
                    display: block;
                    img{
                        width: 100%;
                        object-fit: cover;
                    }
            }
        }

        .column-two{
            width: 58%;
            .page-holder{
                border-bottom: 1px solid #ccc;
            }


            .pagination{
                margin-bottom: 60px;
            }

            .announcement{
                margin-top: 30px;
                .ad-card{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 24px;
                    background: #F6F6F7;
                    border-radius: 13px;
                    margin-bottom: 20px;

                    .left{
                        margin-right: 16px;
                    }

                    .right{
                        span{
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 18px;
                            margin-bottom: 10px;
                            color: #757B87;
                            display: block;
                            padding-left: 20px;
                            position: relative;
                            &::before{
                                position: absolute;
                                content: '';
                                width: 13px;
                                background-repeat: no-repeat;
                                top: 2px;
                                height: 12px;
                                background-size: contain;
                                background-image: url('../../assets/icons/date.png');
                                left: 0;
                            }
                        }

                        h2{
                            font-family: IBM Plex Serif;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 23px;
                            color: #2A2A2A;
                        }
                    }
                }
            }
        }

        .column-thre{
            width: 18%;
        }

        .page-holder{
            h1{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #2D2D2D;
                padding-bottom: 24px;
                padding-left: 45px;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    width: 25px;
                    background: #3987F3;
                    border-radius: 10px;
                    height: 7px;
                    top: 8px;
                }
            }
        }
    }
}