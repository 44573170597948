.desc-block{
    table{
        tbody{
            tr{
                display: flex;
                flex-direction: column;
                td{
                    width: 100% !important;
                    img{
                        width: 100% !important;
                        margin-bottom: 20px !important;
                    }
                    p{
                        padding-left: 0 !important;
                    }
                }
            }
        }
    }
}

