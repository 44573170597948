@media screen and (max-width:1000px) {
    .page-wrapper{
        .main-place{
            .col-one{
                display: none;
            }
            .col-thre{
                display: none;
            }

            .col-two{
                width: 100%;
            }
        }
    }
}